import React, { FC } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { CheckIcon } from '../Icons';
import { InputProps } from '../InputProps';

export interface CheckboxProps extends InputProps {
  small?: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  selected: boolean;
}

const Checkbox: FC<CheckboxProps> = ({
  className,
  disabled = false,
  handleChange,
  label,
  name,
  selected = undefined,
}) => {
  const id = `checkbox-${name}`;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => handleChange(e);

  const controlClasses = classnames('control');
  return (
    <label className={className} htmlFor={id}>
      <input
        id={id}
        disabled={disabled}
        type="checkbox"
        onChange={onChange}
        checked={selected}
      />
      <div className={controlClasses}>
        <CheckIcon />
      </div>
      <StyledLabel>{label}</StyledLabel>
    </label>
  );
};
const StyledLabel = styled.span`
  margin-top: ${(p) => `${p.theme.spacings.ultraSmall}px`};
`;
export default styled(Checkbox)`
  display: inline-flex;
  width: auto;
  page-break-inside: avoid;
  break-inside: avoid-column;
  vertical-align: middle;
  align-items: flex-start;
  cursor: pointer;
  font-size: ${(p) => (p.labelVariant === 'medium' ? p.theme.fontSize : p.theme.fontSizeSmall)};
  margin-bottom: ${(p) => `${p.theme.spacings.medium}px`};
  margin-right: ${(p) => `${p.theme.spacings.medium}px`};
  & .control {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${(p) => `1px solid ${p.theme.colors.grayLight}`};
    background-color: white;
    border-radius: 2px;
    margin-right: ${(p) => `${p.theme.spacings.large}px`};
    position: relative;
    transition: 0.12s;
    &--error {
      border-color: ${(p) => p.theme.colors.error};
    }
    & svg {
      width: inherit;
      height: inherit;
      opacity: 0;
      transform: scale(1.4);
      transition: 0.12s;
      & * {
        fill: white;
      }
    }
  }
  & input {
    position: absolute;
    padding: 0;
    margin: 0;
    width: 0;
    height: 0;
    visibility: hidden;
    &:checked {
      ~ .control {
        background-color: ${(p) => p.theme.colors.primaryDark};
        border: ${(p) => `1px solid ${p.theme.colors.primaryDark}`};
      }
    }

    &:checked ~ .control svg {
      opacity: 1;
    }
  }
`;
