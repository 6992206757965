import React from 'react';
import { Paragraph } from 'shared/components/Typography';

export default () => (
  <Paragraph>
    Wyrażam zgodę na przetwarzanie danych osobowych przez PKO Bank Polski SA
    w celu marketingu produktów lub usług podmiotów współpracujących.*
    <br />
    <br />
    *Podmiotem współpracującym jest podmiot należący do Grupy Kapitałowej PKO Banku Polskiego SA,
    wskazany na stronie <a href="https://www.pkobp.pl/grupa">www.pkobp.pl/grupa</a> oraz podmiot,
    który zawarł z PKO Bankiem Polskim SA umowę związaną ze świadczonymi przez PKO Bank Polski SA
    usługami bankowymi, wskazany na stronie <a href="https://www.pkobp.pl/podmioty">www.pkobp.pl/podmioty</a>.
  </Paragraph>
);
