import { Option } from 'types/option';

export const activityTypes: Option[] = [
  { label: 'Samozatrudnienie', value: 'SELF' },
  { label: 'Mikroprzedsiębiorstwo (do 9 pracowników)', value: 'MICRO' },
  { label: 'Małe przedsiębiorstwo (do 50 pracowników)', value: 'MINI' },
  { label: 'Średnie przedsiębiorstwo (do 250 pracowników)', value: 'MEDIUM' },
  { label: 'Duże przedsiębiorstwo (powyżej 250 pracowników)', value: 'LARGE' },
];

export const topicCodes: Option[] = [
  { value: 'COV19_PFR_SUBVENTION', label: 'Wniosek u subwencję Polskiego Funduszu Rozwoju' },
  { value: 'COV19_CARE_ALLOWANCE', label: 'Zasiłek opiekuńczy z powodu COVID19' },
  { value: 'COV19_SICK_BENEFIT', label: 'Zasiłek chorobowy z powodu COVID19' },
  { value: 'COV19_RELIEF_REMISSION', label: 'ZUS – dostępne ulgi w spłacie oraz umorzenia' },
  {
    value: 'COV19_ZUS_DIGITAL_DOC_TRANSFER',
    label: 'ZUS – elektroniczne przekazywanie dokumentów',
  },
  { value: 'COV19_ACTIVITY_SUSPENSION', label: 'Zawieszenie działalności gospodarczej' },
  {
    value: 'COV19_TAX_PAYMENT_DEFERR',
    label: 'Odroczenie terminów płatności podatków i zaległości podatkowych',
  },
  { value: 'COV19_BGK_LOAN_GUARANTEES', label: 'Gwarancje kredytowe BGK' },
  { value: 'COV19_REMOTE_SIGNING', label: 'Zdalne podpisywanie dokumentów' },
  { value: 'COV19_SELF_EMPLOYED_FURLOUGH', label: 'Świadczenia postojowe dla samozatrudnionych' },
  { value: 'COV19_EMPLOYEE_SALARY_COFINANCING', label: 'Dofinansowania wynagrodzeń pracowników' },
  { value: 'COV19_PROLONG_FEE_CANCEL', label: 'Zniesienie opłat prolongacyjnych' },
  { value: 'COV19_DONATION', label: 'Darowizny na przeciwdziałanie COVID19' },
  { value: 'COV19_LOSS_2020', label: 'Odliczanie strat z 2020 r. od dochodu z 2019 r.' },
  {
    value: 'COV19_OBLIGATION_DEFERRING',
    label: 'Odroczenie niektórych obowiązków w prowadzeniu działalności gosp.',
  },
  { value: 'OTHER', label: 'Inne' },
];

export const extraAgreementsUrls: string[] = [
  '/indywidualny/kredythipoteczny',
  '/indywidualny/ubezpieczenienazyciedokredytuhipotecznego',
  '/indywidualny/ubezpieczeniekomunikacyjne',
];
