import React, { FC } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import { transitionTimeSeconds } from 'styles/theme';

interface FadeInTransitionProps {
  className?: string;
  isOpen: boolean;
}

export const ContentWithTransitionStyles = styled.div`
  &.fade-in-transition-enter {
    opacity: 0;
  }
  &.fade-in-transition-enter-active {
    transition: all ${props => props.theme.transitionTime};
    opacity: 1;
  }
  &.fade-in-transition-exit {
    opacity: 1;
  }
  &.fade-in-transition-exit-active {
    transition: all ${props => props.theme.transitionTime};
    opacity: 0;
  }
`;

const FadeInTransition: FC<FadeInTransitionProps> = ({ className, children, isOpen }) => {
  return (
    <CSSTransition
      in={isOpen}
      classNames="fade-in-transition"
      className={className}
      unmountOnExit
      timeout={transitionTimeSeconds}
    >
      <ContentWithTransitionStyles>{children}</ContentWithTransitionStyles>
    </CSSTransition>
  );
};

export default FadeInTransition;
