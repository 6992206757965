import React, { FC, useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { routes } from 'constants/routes';
import Registration from 'screens/Registration';
import Success from 'screens/Registration/components/Success';
import { devices } from 'styles/devices';
import Footer from 'shared/components/Footer';
import EnvBanner from 'shared/components/EnvBanner';
import Confirm from 'screens/Registration/components/Confirm';
import GoToMeeting from 'screens/GoToMeeting';
import { useFormInitialSettingsState, useFormActions } from 'store/form/hooks';

const MainContent = styled.div`
  width: 100%;
  max-width: 1020px;
  padding: 64px 15px;
  @media ${devices.mobile} {
    padding: 30px 15px;
  }
`;
const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;
const App: FC = () => {
  const { fetchInitialSettings } = useFormActions();
  const initialSettings = useFormInitialSettingsState();
  useEffect(() => {
    fetchInitialSettings();
  }, [fetchInitialSettings]);

  return (
    <MainWrapper>
      <EnvBanner />
      <MainContent>
        <Switch>
          {initialSettings.type === 'loaded' && (
            <Route
              path={`(${initialSettings.data.productList.map((product) => product.url).join('|')})`}
              component={Registration}
              exact
            />
          )}

          <Route path={routes.success.path} component={Success} exact />
          <Route path={routes.confirm.path} component={Confirm} exact />
          <Route path={routes.meeting.path} component={GoToMeeting} exact />
        </Switch>
        <Footer />
      </MainContent>
    </MainWrapper>
  );
};

export default App;
