import { RootState } from 'store/rootState';
import { FormState } from './state';
import { InitialSettingsResponse } from 'types/form';

export const formStateSelector = (state: RootState): FormState => state.form;
export const formTypesStateSelector = (state: RootState) => state.form.initialSettings;
export const formTypesDataStateSelector = (state: RootState): InitialSettingsResponse | undefined =>
  state.form.initialSettings.type === 'loaded' ? state.form.initialSettings.data : undefined;
export const formSendFormDataSelector = (state: RootState) => state.form.sendData;
export const meetingSelector = (state: RootState) => state.form.meeting;
export const acceptMeetingSelector = (state: RootState) => state.form.acceptMeeting;
export const redirectUrlSelector = (state: RootState) => state.form.redirectUrl;