import React, { FC } from 'react';
import styled from 'styled-components';
import { Row } from '../Grid';

const EnvBanner: FC<{ className?: string; env?: string }> = ({ className, env }) =>
  !['dev', 'test'].includes(env || '') ? null : (
    <Row className={className} justify="center" align="center">{`Środowisko: ${
      env === 'dev' ? 'Deweloperskie' : 'Testowe'
    }`}</Row>
  );

export default styled(EnvBanner).attrs({ env: process.env.REACT_APP_TYPE })`
  width: 100%;
  height: 50px;
  background-color: ${(p) => (p.env === 'dev' ? p.theme.colors.green : p.theme.colors.yellow)};
`;
