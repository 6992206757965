import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as actions from './actions';
import { FormState, initialFormState } from './state';

export const formReducer = reducerWithInitialState(initialFormState)
  .case(
    actions.fetchInitialSettings.started,
    (state): FormState => ({
      ...state,
      initialSettings: {
        data: undefined,
        type: 'loading',
      },
    }),
  )
  .case(
    actions.fetchInitialSettings.done,
    (state, { result }): FormState => ({
      ...state,
      initialSettings: { data: result, type: 'loaded' },
    }),
  )
  .case(
    actions.fetchInitialSettings.failed,
    (state, { error }): FormState => ({
      ...state,
      initialSettings: { error, type: 'error' },
    }),
  )
  .case(
    actions.sendFormData.started,
    (state): FormState => ({
      ...state,
      sendData: {
        data: undefined,
        type: 'loading',
      },
    }),
  )
  .case(
    actions.sendFormData.done,
    (state, { result }): FormState => ({
      ...state,
      sendData: { data: result, type: 'loaded' },
    }),
  )
  .case(
    actions.sendFormData.failed,
    (state, { error }): FormState => ({
      ...state,
      sendData: { error, type: 'error' },
    }),
  )
  .case(
    actions.fetchMeetingData.started,
    (state): FormState => ({
      ...state,
      meeting: {
        data: undefined,
        type: 'loading',
      },
    }),
  )
  .case(
    actions.fetchMeetingData.done,
    (state, { result }): FormState => ({
      ...state,
      meeting: { data: result, type: 'loaded' },
    }),
  )
  .case(
    actions.fetchMeetingData.failed,
    (state, { error }): FormState => ({
      ...state,
      meeting: { error, type: 'error' },
    }),
  )
  .case(
    actions.acceptMeeting.started,
    (state): FormState => ({
      ...state,
      acceptMeeting: {
        data: undefined,
        type: 'loading',
      },
    }),
  )
  .case(
    actions.acceptMeeting.done,
    (state, { result }): FormState => ({
      ...state,
      acceptMeeting: { data: result, type: 'loaded' },
    }),
  )
  .case(
    actions.acceptMeeting.failed,
    (state, { error }): FormState => ({
      ...state,
      acceptMeeting: { error, type: 'error' },
    }),
  )
  .case(
    actions.setRedirectUrl,
    (state, payload): FormState => ({
      ...state,
      redirectUrl: payload,
    }),
  );
