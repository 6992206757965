import { FormikErrors } from 'formik';

export interface ConfirmValues {
  0?: string;
  1?: string;
  2?: string;
  3?: string;
  4?: string;
  5?: string;
}
export const initialValues: ConfirmValues = {
  0: '',
  1: '',
  2: '',
  3: '',
  4: '',
  5: '',
};

const requiredFields: Array<keyof ConfirmValues> = [0, 1, 2, 3, 4, 5];

export default (values: ConfirmValues) => {
  const errors: FormikErrors<ConfirmValues> = {};
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'requiredField';
    }
  });

  return errors;
};

export const valuesToToken = (values: ConfirmValues): string =>
  requiredFields.reduce((acc, v) => `${acc}${values[v]}`, '');
