import styled, { css } from 'styled-components';
import { boxShadow } from 'styles/theme';

const popoverStyles = css`
  background: white;
  padding: ${p => p.theme.spacings.large}px;
  ${boxShadow}
  border-radius: 2px;
`;

export const PopOverContent = styled.div<{ unstyled?: boolean }>`
  ${p => (p.unstyled ? '' : popoverStyles)}
`;
