import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';
import FieldError from '../FieldError';
import FieldLabel, { LabelVariant } from 'shared/components/FieldLabel';
import InfoButton from 'shared/components/InfoButton';
import { smallBorderRadius } from 'styles/theme';

const StyledFieldError = styled(FieldError)<{ floating: boolean }>`
  ${(p) => (p.floating ? 'position: absolute;' : '')}
  margin-top: ${(p) => p.theme.spacings.ultraSmall}px;
`;

interface FieldWrapperProps {
  className?: string;
  name: string;
  label?: ReactNode;
  labelVariant?: LabelVariant;
  floatingError?: boolean;
  required?: boolean;
  infoText?: string;
  hasError?: boolean;
}

const FieldWrapper: FC<FieldWrapperProps> = ({
  name,
  className,
  labelVariant,
  children,
  label,
  floatingError = true,
  required,
  infoText,
}) => (
  <FieldContainer className={className}>
    {label && (
      <LabelDiv>
        <StyledFieldLabel variant={labelVariant} required={required}>
          {label}
        </StyledFieldLabel>
        {infoText && <InfoButton infoText={infoText} />}
      </LabelDiv>
    )}
    {children}
    <StyledFieldError name={name} floating={floatingError} />
  </FieldContainer>
);

const FieldContainer = styled.div`
  width: 100%;
  margin-bottom: ${(p) => `${p.theme.spacings.extraLarge}px`};
  margin-right: ${(p) => `${p.theme.spacings.medium}px`};
`;

const LabelDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${(p) => `${p.theme.spacings.ultraSmall}px`};
`;

const StyledFieldLabel = styled(FieldLabel)`
  margin-bottom: 0;
`;

export default styled(FieldWrapper)`
  .react-select__control {
    ${smallBorderRadius}
    ${(p) => (p.hasError ? `border: 1px solid ${p.theme.colors.error} !important;` : ``)}
  }
`;
