import React, { FC } from 'react';
import styled from 'styled-components';
import FieldWrapper from '../FieldWrapper';

type Orientation = 'vertical' | 'horizontal';

interface ControlGroupProps {
  className?: string;
  label?: string;
  orientation?: Orientation;
  name: string;
  required?: boolean;
}

const ControlGroup: FC<ControlGroupProps> = ({
  className,
  children,
  label,
  name,
  orientation = 'vertical',
  required,
}) => {
  return (
    <FieldWrapper name={name} label={label} required={required}>
      <div className={className}>{children}</div>
    </FieldWrapper>
  );
};

export default styled(ControlGroup)`
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${(p) => (p.orientation === 'vertical' ? 'column' : 'row')};
  & .label {
    width: auto;
    color: ${(p) => p.theme.colors.grayMedium};
    font-size: ${(p) => p.theme.fontSizeXSmall};
    margin-bottom: ${(p) => p.theme.spacings.large};
  }
`;
