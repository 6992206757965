import React, { FC } from 'react';
import styled from 'styled-components';
import { BulbIcon } from '../Icons';

const Tip: FC<{ className?: string }> = ({ children, className }) => (
  <div className={className}>
    <div className={`${className}__bulb`}>
      <BulbIcon />
    </div>
    <div className={`${className}__content`}>{children}</div>
  </div>
);

export default styled(Tip)`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #f2f2f2;
  padding: ${(p) => p.theme.spacings.large}px;
  border-radius: ${(p) => p.theme.borderRadius};
  a {
    color: ${(p) => p.theme.colors.primary};
    text-decoration: none;
  }
  strong {
    margin-right: ${(p) => `${p.theme.spacings.ultraSmall}px`};
  }
  &__bulb {
    display: flex;
    flex: 0 0 40px;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: white;
    margin-right: ${(p) => p.theme.spacings.large}px;
  }
  &__content {
    font-size: ${(p) => p.theme.fontSizeSmall};
  }
  margin-bottom: ${(p) => p.theme.spacings.huge}px;
`;
