import React, { useRef, FC, useState } from 'react';
import styled from 'styled-components';
import PopOver from '../PopOver';
import { InfoIcon } from '../Icons';

interface InfoButtonProps {
  infoText: string;
}

const StyledButton = styled.button`
  border: none;
  width: 21px;
  height: 21px;
  padding: 0;
  transition: all 0.3s;
  cursor: pointer;
  outline: none;
  margin-left: ${p => `${p.theme.spacings.small}px`};
  & svg * {
    fill: ${p => p.theme.colors.grayIcon};
  }
  &:hover {
    & svg * {
      fill: ${p => p.theme.colors.primary};
    }
  }
`;

const StyledText = styled.p`
  color: ${p => p.theme.colors.grayUltraLight};
`;

const InfoButton: FC<InfoButtonProps> = ({ infoText }) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <StyledButton onClick={() => setIsOpen(!isOpen)} ref={ref}>
        <InfoIcon />
      </StyledButton>
      <PopOver anchorEl={ref.current} isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <StyledText>{infoText}</StyledText>
      </PopOver>
    </>
  );
};

export default InfoButton;
