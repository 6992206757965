import { actionCreatorFactory } from 'typescript-fsa';
import { AsyncError } from 'store/AsyncState';
import { SendBody, InitialSettingsResponse, SendApplicationResponse } from 'types/form';
import {
  FetchMeetingSuccessResponse,
  AcceptMeetingSuccessResponse,
} from 'types/meetings';

const action = actionCreatorFactory('form');

export const fetchInitialSettings = action.async<void, InitialSettingsResponse, AsyncError>('FETCH_INITIAL_SETTINGS');
export const sendFormData = action.async<SendBody, SendApplicationResponse, AsyncError>(
  'SEND_DATA',
);
export const fetchMeetingData = action.async<string, FetchMeetingSuccessResponse, AsyncError>(
  'FETCH_MEETING_DATA',
);
export const acceptMeeting = action.async<string, AcceptMeetingSuccessResponse, AsyncError>(
  'ACCEPT_MEETING',
);
export const setRedirectUrl = action<string>('SET_REDIRECT_URL');

