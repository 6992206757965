import { useDispatch } from 'react-redux';
import { useMemo, DependencyList } from 'react';
import { bindActionCreators, ActionCreatorsMapObject } from 'redux';

export const useActions = <TAction, T extends ActionCreatorsMapObject<TAction>>(
  actions: T,
  deps: DependencyList = [],
) => {
  const dispatch = useDispatch();
  return useMemo(
    () => bindActionCreators(actions, dispatch),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, ...deps],
  );
};
