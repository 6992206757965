import axios, { AxiosInstance, AxiosError } from 'axios';
import { isServerError } from 'shared/helpers/isAxiosError';
import { ServerError } from 'types/serverError';

const createInstance = ({ headers }: { headers?: Headers } = {}): AxiosInstance => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 10000,
    headers,
  });
  instance.interceptors.response.use(
    (response) => {
      if (isServerError(response.data)) {
        return Promise.reject(response.data);
      }
      return response;
    },
    (error: AxiosError<ServerError>) => {
      return Promise.reject(error);
    },
  );
  return instance;
};

export default createInstance;
