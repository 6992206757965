import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware, { Saga } from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createBrowserHistory } from 'history';
import { rootReducer } from './rootReducer';
import sagas from './rootSagas';

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = composeWithDevTools({
  name: 'Formularz',
});

export const history = createBrowserHistory({});
export const store = createStore(
  rootReducer(history),
  composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history))),
);
sagas.forEach((saga: Saga) => sagaMiddleware.run(saga));
