import React, { ReactNode, FC, useState } from 'react';
import styled from 'styled-components';
import Modal from '../Modal';
import { fontRegular } from 'styles/theme';

interface ModalHyperlinkProps {
  children: ReactNode;
  className?: string;
  label: string;
}

const ModalHyperlink: FC<ModalHyperlinkProps> = ({ children, className, label }) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClick = () => {
    setIsOpen(!isOpen);
  };
  return (
    <button className={className} onClick={onClick} type="button">
      {label}
      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        {children}
      </Modal>
    </button>
  );
};

export default styled(ModalHyperlink)`
  font-family: ${fontRegular}, sans-serif;
  display: inline;
  border: none;
  background: none;
  outline: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  color: ${(p) => p.theme.colors.primary};
  font-size: ${(p) => p.theme.fontSizeSmall};
  text-decoration: none;
  letter-spacing: 0.14px;
  &:hover {
    opacity: 0.7;
  }
`;
