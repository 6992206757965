import React from 'react';
import { Paragraph, H3 } from 'shared/components/Typography';

export default () => (
  <>
    <H3>WAŻNE INFORMACJE </H3>
    <Paragraph>
      W dowolnym momencie można wycofać wszystkie lub wybrane zgody. Wycofanie zgody nie wpływa na
      zgodność z prawem przetwarzania, które dokonano na podstawie zgody przed jej wykonaniem.
    </Paragraph>
    <Paragraph>
      Niezaznaczenie żadnej ze zgód nie jest równoznaczne ze złożeniem sprzeciwu wobec przetwarzania
      danych w celach marketingowych. Z tego względu PKO Bank Polski S.A. (Bank) będzie miał
      możliwości przesyłania listownie informacji marketingowych na temat swoich produktów i usług.
    </Paragraph>
    <Paragraph>
      W każdym momencie można złożyć sprzeciw wobec przetwarzania danych w celach marketingowych np.
      listownie na adres Banku, składając oświadczenie w placówkach, w bankowości elektronicznej lub
      dzwoniąc na infolinię PKO Banku Polskiego S.A.
    </Paragraph>
    <Paragraph>
      Po złożeniu sprzeciwu wobec przetwarzania danych osobowych w celach marketingowych Bank nie
      będzie przetwarzał danych osobowych w celach marketingowych i nie będzie przesyłał ofert oraz
      informacji marketingowych (w tym podmiotów współpracujących) w żadnej formie.
    </Paragraph>
  </>
);
