import styled from 'styled-components';
import { borderRadius } from 'styles/theme';

export default styled.div`
  width: 100%;
  background-color: #c7393e;
  color: white;
  padding: ${(p) => `${p.theme.spacings.medium}px ${p.theme.spacings.large}px`};
  margin: ${(p) => `${p.theme.spacings.medium}px 0 ${p.theme.spacings.large}px`};
  ${borderRadius};
`;
