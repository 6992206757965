const PHONE_NUMBER_LENGTH = 9;

export function replaceMaskChars(text: string): string {
  return text.replace(/[_-\s]/g, '');
}

export function isValidName(name: string) {
  if (/[!@#$%^&*()[\]{};'<>?+=]/.test(name)) {
    return false;
  }
  return true;
}
export function isValidEmail(email: string) {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.)(\w{2,4})$/.test(email)) {
    return true;
  }
  return false;
}

export function hasNumbersOnly(text: string) {
  return /^\d+$/.test(text);
}

export function isValidRegon(regon?: string) {
  if (!regon || !/^(\d{9}|\d{14})$/.test(regon)) {
    return false;
  }
  return true;
}

export function isValidPhoneNumber(phoneNumber: string) {
  if (!phoneNumber) {
    return false;
  }
  const numberWithoutMask = replaceMaskChars(phoneNumber).replace('+48', '');
  if (
    !numberWithoutMask ||
    numberWithoutMask.length !== PHONE_NUMBER_LENGTH ||
    /([0-9])\1{8}/.test(numberWithoutMask)
  ) {
    return false;
  }
  return true;
}
