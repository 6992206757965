import { DefaultTheme, css } from 'styled-components';

export const secondaryColor = '#FFFFFF';
export const textColor = '#636363';
export const textGrayColor = '#575b5b';

export const white = '#fff';

export const grayUltraLight = '#707070';
export const grayLight = '#ACACAC';
export const grayMedium = '#625F5D';
export const grayDark = '#121212';
export const grayLine = '#dadada';
export const grayIcon = '#C7C7C7';

export const disabledColor = '#cccaca';

export const okColor = '#29b242';
export const okBackground = '#eafbed';

export const warningColor = '#fa8400';

export const borderWidth = 1;

export type NumberValues = 0 | 4 | 8 | 12 | 16 | 24 | 28 | 32 | 38 | 48 | 56 | 100 | 180;
export const contentOffset: NumberValues = 32;

export const fontRegular = 'PKOBankPolski';

export const spacingUltraSmall = 4;
export const spacingSmall = 8;
export const spacingMedium = 12;
export const spacingLarge = 16;
export const spacingExtraLarge = 28;
export const spacingHuge = 32;

export const transitionTimeSeconds = 0.12;

export const theme: DefaultTheme = {
  colors: {
    primary: '#003574',
    primaryDark: '#003574',
    secondary: secondaryColor,
    text: textColor,
    disabled: disabledColor,
    transparent: 'rgba(1,1,1,0)',
    grayDark,
    grayMedium,
    grayLine,
    grayLight,
    grayUltraLight,
    grayIcon,
    error: '#CA171D',
    green: '#3fbd3f',
    yellow: '#eeee48',
    red: '#d11632',
    black: '#000000',
  },
  spacings: {
    ultraSmall: spacingUltraSmall,
    small: spacingSmall,
    medium: spacingMedium,
    large: spacingLarge,
    extraLarge: spacingExtraLarge,
    huge: spacingHuge,
  },
  smallBorderRadius: '2px',
  borderRadius: '4px',
  fontSizeButtonMedium: '15px',
  fontSizeLarge: '18px',
  fontSize: '15px',
  fontSizeSmall: '13px',
  fontSizeXSmall: '12px',
  fontSizes: {
    H1: '48px',
    H2: '33px',
    H3: '23px',
    H4: '19px',
    H5: '17px',
    H6: '14px',
  },
  fontWeights: {
    light: 200,
    regular: 400,
    bold: 900,
  },
  contentOffset,
  transitionTime: `${transitionTimeSeconds}s`,
};

export const lineHeight = 1.5;

export const absoluteFill = css`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

export const smallBorderRadius = css`
  border-radius: ${(p) => p.theme.smallBorderRadius};
`;

export const boxShadow = css`
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.35);
`;

export const borderRadius = css`
  border-radius: ${(p) => p.theme.borderRadius};
`;

export const roundedBorder = css`
  border: 1px solid ${(p) => p.theme.colors.grayLine};
  ${borderRadius};
`;
