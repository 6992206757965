import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import { ErrorMessage } from 'formik';
import { useTranslation } from 'shared/hooks/useTranslation';

interface FieldErrorProps {
  name: string;
  className?: string;
}

const FieldError: FC<FieldErrorProps> = ({ name, className }) => {
  const translations = useTranslation();
  return (
    <ErrorMessage
      name={name}
      render={(msg) => (
        <div className={className}>
          {translations.form.errors[msg] || translations.form.errors.defaultFieldError}
        </div>
      )}
    />
  );
};

const errorStyle = css`
  display: block;
  margin-top: ${(p) => p.theme.spacings.small}px;
  color: ${(p) => p.theme.colors.error};
  font-size: ${(p) => p.theme.fontSizeXSmall};
`;
export const Error = styled.div`
  ${errorStyle}
`;

export default styled(FieldError)`
  ${errorStyle}
`;
