import React, { useState, FC, useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';
import { PopOverContent } from './styled-components';
import FadeInTransition from '../FadeInTransition';
import useWindowSize from 'shared/hooks/useWindowSize';
import FullScreenOverlay from 'shared/components/FullScreenOverlay';

export interface PopOverProps {
  anchorEl: Element | null;
  isOpen: boolean;
  onClose: () => void;
  pullRight?: boolean;
  fullWidth?: boolean;
  unstyled?: boolean;
}

interface Position {
  top: number;
  left?: number;
  right?: number;
}

const PopOver: FC<PopOverProps> = ({
  anchorEl,
  isOpen,
  children,
  onClose,
  fullWidth = false,
  pullRight,
  unstyled,
}) => {
  const [position, setPosition] = useState<Position>({ top: 0, left: 0, right: 0 });
  const [width, setWidth] = useState<string>('auto');
  const rootModalContainer = document.getElementById('root') as HTMLElement;

  const myRef = useRef<HTMLDivElement>(null);

  useWindowSize(
    useCallback(() => {
      if (!anchorEl) return;
      const { height, top, left, right, width } = anchorEl.getBoundingClientRect();

      const newPosition: Position = {
        top: top + height,
      };

      // If content won't fit the screen in vertical way
      // Then show it above element
      if (myRef && myRef.current) {
        const { height: popOverHeight } = myRef.current.getBoundingClientRect();

        if (top + height + popOverHeight > window.innerHeight) {
          newPosition.top = top - popOverHeight;
        }
      }

      if (pullRight) {
        newPosition.right = document.body.clientWidth - window.pageXOffset - right;
      } else {
        newPosition.left = left;
      }
      setPosition(newPosition);
      if (fullWidth) {
        setWidth(`${width}px`);
      }

      return newPosition;
    }, [anchorEl, fullWidth, setWidth, pullRight]),
    anchorEl,
  );

  const markup = (
    <FadeInTransition isOpen={isOpen}>
      <FullScreenOverlay onClick={onClose} />
      <PopOverContent
        unstyled={unstyled}
        style={{
          ...position,
          position: 'fixed',
          width: width,
        }}
        ref={myRef}
      >
        {children}
      </PopOverContent>
    </FadeInTransition>
  );

  return ReactDOM.createPortal(markup, rootModalContainer);
};
export default PopOver;
