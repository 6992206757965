import React, { ReactNode, FC } from 'react';
import styled from 'styled-components';

interface HyperLinkProps {
  href: string;
  children: ReactNode;
  className?: string;
  target?: string;
}

const HyperLink: FC<HyperLinkProps> = ({ href, children, className, target }) => (
  <a href={href} className={className} target={target}>
    {children}
  </a>
);

export default styled(HyperLink)`
  color: ${(p) => p.theme.colors.primary};
  font-size: ${(p) => p.theme.fontSizeSmall};
  text-decoration: none;
  letter-spacing: 0.14px;
  &:hover {
    opacity: 0.7;
  }
`;
