import React, { useEffect } from 'react';
import Header from 'screens/Registration/components/Header';
import { Col } from 'shared/components/Grid';
import { Paragraph } from 'shared/components/Typography';

import Button from 'shared/components/Button';
import Divider from 'shared/components/Divider';

import { useParams, useHistory } from 'react-router';
import { useFormActions, useMeetingState, useAcceptMeetingState } from 'store/form/hooks';
import CircleLoader, { LoaderWrapper } from 'shared/components/CircleLoader';
import { routes } from 'constants/routes';
import { useTranslation } from 'shared/hooks/useTranslation';
import {
  StyledRow,
  StyledParagraph,
  StyledHyperlink,
  StyledCol,
  Buttons,
} from './styled-components';

export default () => {
  const translation = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const { fetchMeeting, acceptMeeting } = useFormActions();
  const meetingState = useMeetingState();
  const acceptMeetingState = useAcceptMeetingState();
  useEffect(() => {
    fetchMeeting(id);
  }, [id, fetchMeeting]);
  
  return (
    <>
      {(meetingState.type === 'error' || acceptMeetingState.type === 'error') && (
        <>
          <Header title={translation.meeting.error.header} />
          <Paragraph>{translation.meeting.error.paragraph}</Paragraph>
          <Button
            onClick={() => {
              history.push(routes.default.path);
            }}
          >
            {translation.meeting.error.button}
          </Button>
        </>
      )}
      {meetingState.type === 'loading' && (
        <LoaderWrapper>
          <CircleLoader />
        </LoaderWrapper>
      )}
      {meetingState.type === 'loaded' && acceptMeetingState.type !== 'error' && (
        <>
          <Header title={translation.meeting.heading} />
          <div>
            <StyledRow>
              <StyledCol>
                <img src={require('assets/gotomeeting.png')} alt="PKO BP" />
              </StyledCol>
              <Col>
                <StyledParagraph>
                  {translation.meeting.paragraph}
                  <br />
                  <StyledHyperlink href="https://www.pkobp.pl/rodo/" target="_blank">
                    {translation.meeting.paragraphHyperlink}
                  </StyledHyperlink>
                </StyledParagraph>
                <StyledParagraph>
                  <strong>{translation.meeting.paragraphStrong}</strong>
                </StyledParagraph>
              </Col>
            </StyledRow>
            <Divider />
            <Buttons>
              <Button color="secondary" element="a" href="https://www.pkobp.pl/doradcaonline">
                {translation.meeting.cancelButton}
              </Button>
              <Button
                onClick={() => {
                  acceptMeeting(id);
                }}
                isLoading={acceptMeetingState.type === 'loading'}
              >
                {translation.meeting.acceptButton}
              </Button>
            </Buttons>
          </div>
        </>
      )}
    </>
  );
};
