import { useSelector } from 'react-redux';
import { useActions } from 'shared/hooks/useActions';
import { fetchInitialSettings, sendFormData, fetchMeetingData, acceptMeeting } from './actions';
import {
  formStateSelector,
  formSendFormDataSelector,
  formTypesStateSelector,
  formTypesDataStateSelector,
  meetingSelector,
  acceptMeetingSelector,
  redirectUrlSelector,
} from './selectors';
import { InitialSettingsResponse } from 'types/form';

export const useFormState = () => useSelector(formStateSelector);
export const useMeetingState = () => useSelector(meetingSelector);
export const useRedirectUrl = () => useSelector(redirectUrlSelector);
export const useAcceptMeetingState = () => useSelector(acceptMeetingSelector);
export const useFormInitialSettingsState = () => useSelector(formTypesStateSelector);
export const useFormInitialSettingsDataState: () => InitialSettingsResponse | undefined = () =>
  useSelector(formTypesDataStateSelector);
export const useFormSendFormDataState = () => useSelector(formSendFormDataSelector);

export const useFormActions = () =>
  useActions({
    fetchInitialSettings: fetchInitialSettings.started,
    sendFormData: sendFormData.started,
    fetchMeeting: fetchMeetingData.started,
    acceptMeeting: acceptMeeting.started,
  });
