import React, { FC } from 'react';
import styled from 'styled-components';

import { H1 } from 'shared/components/Typography';
import { devices } from 'styles/devices';
import { useTranslation } from 'shared/hooks/useTranslation';

const StyledLogo = styled.div`
  width: 75px;
  height: 93px;
  img {
    max-width: 100%;
  }
  @media ${devices.mobile} {
    margin-bottom: ${(p) => p.theme.spacings.large}px;
  }
`;
const Heading = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${devices.mobile} {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: ${(p) => p.theme.fontSizes.H2};
    margin-bottom: 0;
  }
`;

interface HeaderProps {
  title?: string;
}

const Header: FC<HeaderProps> = ({ title }) => {
  const translations = useTranslation();

  return (
    <Heading>
      <H1>{title || translations.form.title}</H1>
      <StyledLogo>
        <img src={require('assets/pko.svg')} alt="PKOBP logo" />
      </StyledLogo>
    </Heading>
  );
};

export default Header;
