import React, { FC, ReactNode } from 'react';
import { useField } from 'formik';
import FieldWrapper from '../FieldWrapper';
import { InputProps } from '../InputProps';
import TextInput from '../TextInput';
import { hasNumbersOnly } from 'shared/helpers/validation';

export interface TextFieldProps extends InputProps {
  icon?: ReactNode;
  formatValue?: (value: string) => string | number;
  onChange?: (value: string | number) => void;
  floatingError?: boolean;
  infoText?: string;
  mask?: string;
  maskPlaceholder?: string;
  onlyNumbers?: boolean;
  maxLength?: number;
}

const TextField: FC<TextFieldProps> = ({
  className,
  label,
  labelVariant,
  name,
  formatValue,
  onChange,
  floatingError,
  required,
  infoText,
  type = 'text',
  onlyNumbers,
  maxLength,
  ...props
}) => {
  const [field, meta] = useField({ name });

  const handleBlur = (e: React.ChangeEvent<Element>): void => {
    const element = e.target as HTMLInputElement;

    field.onBlur(e);

    if (formatValue) {
      const newValue = formatValue(element.value);
      field.onChange({ target: { value: newValue, name: field.name } });
      return;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (onlyNumbers && !hasNumbersOnly(e.target.value) && e.target.value.length) {
      e.preventDefault();
      return;
    }
    if (maxLength && e.target.value.length > maxLength) {
      return;
    }
    if (onChange) {
      onChange(e.target.value);
    }
    field.onChange(e);
  };

  return (
    <FieldWrapper
      name={name}
      label={label}
      labelVariant={labelVariant}
      className={className}
      floatingError={floatingError}
      required={required}
      infoText={infoText}
    >
      <TextInput
        {...field}
        {...props}
        type={type}
        onBlur={handleBlur}
        onChange={handleChange}
        hasError={meta.touched && !!meta.error}
      />
    </FieldWrapper>
  );
};

export default TextField;

export const PasswordField = (props: TextFieldProps) => (
  <TextField inputProps={{ type: 'password', ...props.inputProps }} {...props} />
);

export interface NumberFieldProps extends TextFieldProps {
  step?: number;
  min?: number;
  max?: number;
}

export const NumberField: FC<NumberFieldProps> = ({ step, min, max, ...props }) => (
  <TextField
    {...props}
    inputProps={{
      type: 'number',
      min: min || '0',
      max: max,
      step: step || '0.01',
      ...props.inputProps,
    }}
  />
);
