import { PossibleLanguages, Translation, Translations } from 'types/translations';

export const TRANSLATION_POLISH_KEY: PossibleLanguages = 'PL';

const plTranslations: Translation = {
  meeting: {
    heading: 'Zgoda na przetwarzanie danych i nagrywanie',
    paragraph: `Informujemy, że w związku z wybranym kanałem obsługi, poprzez usługę doradca online podane dane oraz wizerunek twarzy i głos będą przetwarzane w celu realizacji kontaktu i obsługi produktów bankowych, na podstawie art. 6 ust. 1 lit. b i f Rozporządzenia. Administratorem danych osobowych jest PKO Bank Polski S.A. `,
    paragraphHyperlink: 'Zapoznaj się z pełną informacją o przetwarzaniu danych osobowych.',
    paragraphStrong:
      'W trosce o bezpieczeństwo i jakość usług świadczonych przez PKO Bank Polski wideorozmowy z naszymi doradcami są nagrywane.',
    acceptButton: 'Zgadzam się',
    cancelButton: 'Nie wyrażam zgody',
    error: {
      header: 'Wystąpił błąd',
      paragraph: `Wybacz, ale dane spotkanie zostało już zakończone. Kliknij w przycisk poniżej, aby wypełnić formularz ponownie.`,
      button: 'Wypełnij formularz',
    },
  },
  confirm: {
    title: 'Potwierdź swój numer telefonu podając kod weryfikacyjny z SMS-a',
    description: 'Wpisz kod wysłany w wiadomości SMS',
  },
  common: {
    confirm: 'Potwierdź',
    confirmation: 'POTWIERDZENIE',
  },
  form: {
    title: 'Umów bezpłatną wideorozmowę z doradcą',
    paragraph: `Do wideorozmowy będziesz potrzebować komputera lub telefonu z przeglądarką internetową (zalecana przeglądarka to Chrome – usługa działa również na innych przeglądarkach wskazanych na stronie <a href="https://support.google.com/meet/answer/7317473?hl=pl" target="_blank">Google Meet</a>). Wideorozmowy, do których dołączysz przez telefon, będą prowadzone w aplikacji Google Meet.`,
    paragraphOnlyPko: 'Wideorozmowa jest dostępna dla klientów PKO Banku Polskiego',
    headings: {
      companyData: 'Informacje o firmie',
      userData: 'Twoje dane',
      agreements: 'Oświadczenia',
    },
    labels: {
      companyName: 'Pełna nazwa firmy',
      activityType: 'Rodzaj prowadzonej działalności',
      regon: 'REGON',
      supportType: 'Rodzaj wsparcia',
      name: 'Imię i nazwisko',
      email: 'Adres e-mail',
      reEmail: 'Powtórz adres e-mail',
      phoneNumber: 'Numer telefonu',
      nipNumber: 'Numer NIP',
      acceptAll: 'Po zapoznaniu się z poniższymi oświadczeniami akceptuję ich treść.',
      acceptPersonalData: 'Informacja o przetwarzaniu danych osobowych.',
      acceptMarketing: 'Zgoda na marketing telefoniczny i elektroniczny banku.',
      acceptExternalMarketing: 'Informacja o przetwarzaniu danych osobowych podmiotów współpracujących z bankiem.',
      acceptExternalTelemarketing: 'Zgoda na marketing telefoniczny i elektroniczny podmiotów współpracujących z bankiem.',
      seeAll: 'Zobacz pełną treść',
      getKnownWithAllInfo: 'Zapoznaj się z pełną informacją.',
      getKnownWithAllPersonalData:
        'Zapoznaj się z pełną informacją o przetwarzaniu danych osobowych.',
      objections: 'Sprzeciw wobec przetwarzania danych osobowych w celach marketingowych.',
      adminData: `Informujemy, że w związku z wybranym kanałem obsługi, poprzez usługę doradca online podane dane oraz wizerunek twarzy i głos będą przetwarzane w celu realizacji kontaktu i obsługi produktów bankowych, na podstawie art. 6 ust. 1 lit. b i f Rozporządzenia. Administratorem danych osobowych jest PKO Bank Polski S.A.`,
      adminDataLink: `Zapoznaj się z pełną informacją o przetwarzaniu danych osobowych.`,
      send: 'Wyślij',
    },
    errors: {
      required: 'To pole jest wymagane',
      wrongRegonFormat: 'Wpisz poprawny REGON',
      invalidEmailFormat: 'Wpisz poprawny adres e-mail',
      reEmail: 'Adresy e-mail nie są identyczne',
      wrongPhoneFormat: 'Wpisz poprawny numer telefonu',
      formHasErrors: 'Uzupełnij poprawnie formularz',
      defaultFieldError: 'Błąd pola',
      noResults: 'Brak wyników',
      noSpecialCharacters: 'Pole nie może zawierać znaków specjalnych',
    },
    apiErrors: {
      fetchTypesError:
        'Wybacz, ale wystąpił problem z załadowaniem danych. Spróbuj przeładować stronę.',
      sendDataError:
        'Wybacz, ale wystąpił problem z wysłaniem formularza. Spróbuj ponownie później',
      wrongCodeError: 'Wprowadzony kod jest nieprawidłowy. Podaj kod weryfikacyjny z SMS.',
    },
  },
  success: {
    heading: 'Twoja prośba o kontakt została wysłana',
    paragraph: `Na Twój adres e-mail wysłaliśmy opis następnych kroków. Przed rozmową z naszym doradcą warto
    przetestować połączenie. Konsultacje z doradcą odbywają się w dni robocze od 09:00 do 17:00.`,
    ok: 'OK',
  },
  footer: {
    copyRight: '2020 PKO Bank Polski',
    bicCode: 'Kod BIC (Swift): BPKOPLPW',
    phoneNumber: 'Infolinia PKO Banku Polskiego 800 302 302',
    technicalHelp: 'Pomoc techniczna 22 3555 987',
  },
};
const allTranslations: Translations = {
  [TRANSLATION_POLISH_KEY]: plTranslations,
};

export default allTranslations;
