import { useEffect } from 'react';

export default (callback: () => void, param: any) =>
  useEffect(() => {
    callback();
    window.addEventListener('resize', callback);
    window.addEventListener('scroll', callback);
    return () => {
      window.removeEventListener('scroll', callback);
      window.removeEventListener('resize', callback);
    };
  }, [param, callback]);
