import styled from 'styled-components';
import React, { FC } from 'react';

export type LabelVariant = 'small' | 'medium';

interface FieldLabelProps {
  variant?: LabelVariant;
  required?: boolean;
  className?: string;
}

const FieldLabel = styled.label<FieldLabelProps>`
  display: block;
  width: auto;
  font-size: ${(p) => p.theme.fontSizeSmall};
  font-weight: ${(p) => p.theme.fontWeights.light};
  color: ${(p) => p.theme.colors.black};
  margin-bottom: 0.5rem;
  white-space: nowrap;
`;

const LabelWithAsterisk: FC<FieldLabelProps> = ({ variant, children, className }) => (
  <FieldLabel className={className} variant={variant}>
    {children}
  </FieldLabel>
);

export default LabelWithAsterisk;
