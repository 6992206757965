import React, { FC, DetailedHTMLProps, InputHTMLAttributes } from 'react';
import { useField } from 'formik';
import styled from 'styled-components';
import { findIndex, isEmpty } from 'lodash';

interface CodeInputProps
  extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  hasError?: boolean;
  className?: string;
}

const CodeInput: FC<CodeInputProps> = ({ className, hasError, ...props }) => (
  <input className={className} {...props} />
);

const StyledCodeInput = styled(CodeInput)`
  border-radius: ${(p) => p.theme.borderRadius};
  margin-right: 15px;
  padding: 7px;
  background: white;
  outline: none;
  text-align: center;
  font-size: 16px;
  border: ${(p) => (p.hasError ? `1px solid ${p.theme.colors.red}` : '1px solid #e6e8eb')};
  &:focus {
    border: 1px solid ${(p) => p.theme.colors.primary};
  }
`;

export interface CodeFieldProps {
  autoFocus?: boolean;
  name: string;
}

const CodeField: FC<CodeFieldProps> = ({ name, autoFocus }) => {
  const [field, meta] = useField({ name });

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    field.onChange(e);
    if (isEmpty(e.target.value)) return;
    const form = e.target.form;
    if (form === null) return;
    const index = findIndex(form, e.target);
    const inputs = Array.from(form.elements).filter((i) => i instanceof HTMLInputElement);
    if (index >= inputs.length - 1) return;
    (inputs[index + 1] as HTMLInputElement).focus();
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const key = e.keyCode || e.charCode;
    if (key === 8 || key === 46) {
      const form = e.currentTarget.form;
      if (form === null) return;
      const index = findIndex(form, e.target);
      const inputs = Array.from(form.elements).filter((i) => i instanceof HTMLInputElement);
      if (index <= 0) return;
      (inputs[index - 1] as HTMLInputElement).focus();
      return;
    }

    if (!isEmpty(e.currentTarget.value)) {
      const form = e.currentTarget.form;
      if (form === null) return;
      const index = findIndex(form, e.target);
      const inputs = Array.from(form.elements).filter((i) => i instanceof HTMLInputElement);
      if (index >= inputs.length - 1) return;
      (inputs[index + 1] as HTMLInputElement).focus();
      return;
    }
  };

  return (
    <StyledCodeInput
      {...field}
      autoFocus={autoFocus}
      type="tel"
      onChange={handleOnChange}
      onKeyUp={handleKeyUp}
      maxLength={1}
      min={0}
      max={9}
      tabIndex={1}
      autoComplete="one-time-code"
      size={1}
      hasError={meta.touched && !!meta.error}
    />
  );
};

export default CodeField;
