export class RecaptchaService {
  isReady = false;
  constructor() {
    grecaptcha.ready(() => {
      this.isReady = true;
    });
  }
  execute = (action: string): Promise<string | null> => {
    return new Promise((resolve, reject) => {
      try {
        if (!this.isReady) {
          reject(null);
        }
        return grecaptcha
          .execute(process.env.REACT_APP_RECAPTCHA_KEY!, { action })
          .then((token: string) => {
            if (!token) reject();
            resolve(token);
          });
      } catch (e) {
        return reject(e);
      }
    });
  };
}

export default new RecaptchaService();
