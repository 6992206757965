import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Paragraph, H3 } from 'shared/components/Typography';
import Button from 'shared/components/Button';
import { TickIcon } from 'shared/components/Icons';
import { useTranslation } from 'shared/hooks/useTranslation';
import { useFormSendFormDataState, useRedirectUrl } from 'store/form/hooks';
import { useHistory } from 'react-router';
import { routes } from 'constants/routes';
import Header from '../Header';

const StyledSuccess = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  max-width: 500px;
  text-align: center;
  svg {
    margin-bottom: ${(p) => p.theme.spacings.large}px;
  }
`;

export default () => {
  const translations = useTranslation();
  const history = useHistory();
  const { type } = useFormSendFormDataState();
  const redirectUrl = useRedirectUrl();
  useEffect(() => {
    if (type === 'initial') {
      history.push(routes.default.path);
    }
  }, [type, history]);

  return (
    <>
      <Header />
      <StyledSuccess>
        <TickIcon />
        <H3>{translations.success.heading}</H3>
        <Paragraph>{translations.success.paragraph}</Paragraph>
        <Button element="a" href={redirectUrl}>
          {translations.success.ok}
        </Button>
      </StyledSuccess>
    </>
  );
};
