import styled from 'styled-components';
import { devices } from 'styles/devices';
import Hyperlink from 'shared/components/Hyperlink';
import { Row, Col } from 'shared/components/Grid';
import { Paragraph } from 'shared/components/Typography';

export const StyledCol = styled(Col)`
  max-width: 390px;
  align-items: flex-start;
  img {
    max-width: 100%;
  }
  @media ${devices.mobile} {
    img {
      display: none;
    }
  }
`;
export const StyledRow = styled(Row)`
  align-items: center;
`;
export const StyledParagraph = styled(Paragraph)`
  font-size: ${(p) => p.theme.fontSize};
`;
export const StyledHyperlink = styled(Hyperlink)`
  font-size: ${(p) => p.theme.fontSize};
`;
export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${devices.mobile} {
    flex-direction: column;
    a {
      margin-bottom: ${(p) => p.theme.spacings.medium}px;
    }
  }
`;
