import { AsyncState } from 'store/AsyncState';
import { InitialSettingsResponse, SendApplicationResponse } from 'types/form';
import { FetchMeetingSuccessResponse, AcceptMeetingSuccessResponse } from 'types/meetings';

export type FormState = {
  initialSettings: AsyncState<InitialSettingsResponse>;
  sendData: AsyncState<SendApplicationResponse>;
  meeting: AsyncState<FetchMeetingSuccessResponse>;
  acceptMeeting: AsyncState<AcceptMeetingSuccessResponse>;
  redirectUrl: string;
};

export const initialFormState: FormState = {
  initialSettings: {
    type: 'initial',
    data: undefined,
  },
  sendData: {
    type: 'initial',
    data: undefined,
  },
  meeting: {
    type: 'initial',
    data: undefined,
  },
  acceptMeeting: {
    type: 'initial',
    data: undefined,
  },
  redirectUrl: '',
};
