import React from 'react';
import { FormikProps } from 'formik';
import TextField from 'shared/components/TextField';
import { useTranslation } from 'shared/hooks/useTranslation';
import { Row, Col } from 'shared/components/Grid';
import { RegistrationFormValues } from '../../validate';
import { H2 } from 'shared/components/Typography';
import Tip from 'shared/components/Tip';
import Divider from 'shared/components/Divider';
import Button from 'shared/components/Button';
import styled from 'styled-components';
import SelectField from 'shared/components/SelectField';
import { useFormSendFormDataState } from 'store/form/hooks';
import Error from 'shared/components/Error';
import Agreements from '../Agreements';
import { MAX_REGON_LENGTH } from 'constants/form';
import ControlField from 'shared/components/ControlField';
import { Option } from 'types/option';
import { Product } from 'types/form';
import { activityTypes } from 'constants/types';

const StyledSectionHeading = styled(H2)`
  margin-top: 30px;
  margin-bottom: 15px;
`;
const BottomContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;
const StyledError = styled.div`
  color: ${(p) => p.theme.colors.error};
  text-align: center;
  margin: 30px 0 15px;
`;

interface RegistrationFormProps extends FormikProps<RegistrationFormValues> {
  isMSP: boolean;
  currentRoute?: Product;
  topicCodesOptions: Option[];
  showExtraAgreements: boolean;
}

export default React.memo((props: RegistrationFormProps) => {
  const {
    handleSubmit,
    errors,
    submitCount,
    isValid,
    dirty,
    isMSP,
    currentRoute,
    topicCodesOptions,
  } = props;
  const translations = useTranslation();
  const showBottomError = !!submitCount && !!Object.keys(errors).length;
  const { type } = useFormSendFormDataState();

  return (
    <form onSubmit={handleSubmit}>
      <H2>{currentRoute?.description}</H2>
      <Tip>
        <strong dangerouslySetInnerHTML={{ __html: translations.form.paragraph }} />
      </Tip>
      {(isMSP || !currentRoute) && (
        <>
          <StyledSectionHeading> {translations.form.headings.companyData}</StyledSectionHeading>
          <Divider />
          <Row>
            <Col type="short">
              <TextField name="companyName" label={translations.form.labels.companyName} required />
            </Col>
            <Col type="short">
              <TextField
                name="regon"
                label={translations.form.labels.regon}
                required
                onlyNumbers
                maxLength={MAX_REGON_LENGTH}
              />
            </Col>
          </Row>
          <Row>
            <Col type="short">
              <SelectField
                options={activityTypes}
                name="activityTypeCode"
                label={translations.form.labels.activityType}
                required
                noOptionsMessage={() => <div>{translations.form.errors.noResults}</div>}
              />
            </Col>
            {topicCodesOptions.length > 1 && (
              <Col type="short">
                <SelectField
                  options={topicCodesOptions}
                  name="productTopicCode"
                  label={translations.form.labels.supportType}
                  required
                  noOptionsMessage={() => <div>{translations.form.errors.noResults}</div>}
                  disabled={topicCodesOptions.length === 1}
                />
              </Col>
            )}
          </Row>
        </>
      )}
      <StyledSectionHeading> {translations.form.headings.userData}</StyledSectionHeading>
      <Divider />
      <Row>
        <Col type="short">
          <TextField name="name" label={translations.form.labels.name} required />
        </Col>
        <Col type="short">
          <TextField
            name="phoneNumber"
            label={translations.form.labels.phoneNumber}
            required
            type="tel"
            mask="+48 999 999 999"
            maskPlaceholder="+48 123 456 789"
          />
        </Col>
      </Row>
      <Row>
        <Col type="short">
          <TextField
            name="email"
            inputProps={{ type: 'email' }}
            label={translations.form.labels.email}
            required
          />
        </Col>
        <Col type="short">
          <TextField
            name="reEmail"
            inputProps={{ type: 'email' }}
            label={translations.form.labels.reEmail}
            required
            blockPaste
          />
        </Col>
      </Row>
      <ControlField label="Jestem klientem" name="customer" type="checkbox" />
      <StyledSectionHeading>{translations.form.headings.agreements}</StyledSectionHeading>
      <Divider />
      <Agreements {...props} />

      <Divider />
      {showBottomError && <StyledError>{translations.form.errors.formHasErrors}</StyledError>}
      {type === 'error' && <Error>{translations.form.apiErrors.sendDataError}</Error>}
      <BottomContainer>
        <Button type="submit" isLoading={type === 'loading'} disabled={!(isValid && dirty)}>
          {translations.form.labels.send}
        </Button>
      </BottomContainer>
    </form>
  );
});
