import styled, { FlattenSimpleInterpolation, css } from 'styled-components';
import { devices } from 'styles/devices';

type FlexJustifyType =
  | 'flex-start'
  | 'flex-end'
  | 'center'
  | 'space-between'
  | 'space-around'
  | 'space-evenly';

type FlexAlignType = 'flex-start' | 'flex-end' | 'center' | 'stretch' | 'baseline' | 'normal';

interface FlexProps {
  justify?: FlexJustifyType;
  align?: FlexAlignType;
  alignSelf?: 'auto' | FlexAlignType;
}

interface RowProps extends FlexProps {
  direction?: 'vertical' | 'horizontal';
}

const flexStyle = <T extends FlexProps>(flexProps: T): FlattenSimpleInterpolation => css`
  justify-content: ${flexProps.justify};
  align-items: ${flexProps.align};
  alignself: ${flexProps.alignSelf};
`;

export const Row = styled.div<RowProps>`
  display: flex;
  margin: 0 -15px;
  flex-direction: ${(p) => (p.direction === 'vertical' ? 'column' : 'row')};
  @media ${devices.mobile} {
    flex-wrap: wrap;
  }
  ${(p) => flexStyle(p)};
`;

type ColType = 'short' | 'medium' | 'full' | undefined;
interface ColProps extends FlexProps {
  flex?: string;
  type?: ColType;
}

const getColWidth = (type: ColType) => {
  if (!type) {
    return '100%';
  }
  const widths = {
    short: '33.3%',
    medium: '66.6%',
    full: '100%',
  };
  return widths[type];
};

export const Col = styled.div<ColProps>`
  width: ${(p) => getColWidth(p.type)};
  display: flex;
  flex: ${(p) => p.flex};

  @media ${devices.mobile} {
    flex: 100%;
  }
  padding: 0 15px;
  flex-direction: column;
  ${(p) => flexStyle(p)};
`;
