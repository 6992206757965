import translations, { TRANSLATION_POLISH_KEY } from 'constants/translations';

export interface TranslationState {
  currentKey: string;
  data: any;
}

export const initialTranslationState: TranslationState = {
  data: translations[TRANSLATION_POLISH_KEY],
  currentKey: TRANSLATION_POLISH_KEY,
};
