import styled from 'styled-components';
import { absoluteFill, roundedBorder, boxShadow } from 'styles/theme';
import { devices } from 'styles/devices';

const zIndexTop = 99999;
const zIndexBackground = 99998;

export const ModalBackground = styled.div`
  background: ${(p) => p.theme.colors.grayDark};
  opacity: 0.1;
  z-index: ${zIndexBackground};
  ${absoluteFill}
`;
export const ModalCloseButton = styled.button`
  position: absolute;
  right: ${(p) => p.theme.spacings.large}px;
  top: ${(p) => p.theme.spacings.large}px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  svg {
    height: 15px;
    width: 15px;
    fill: ${(p) => p.theme.colors.primary};
  }
  &:hover {
    opacity: 0.6;
  }
  @media ${devices.mobile} {
    position: fixed;
  }
`;
export const ModalContent = styled.div`
  position: relative;
  width: auto;
  max-width: 50%;
  max-height: 90vh;
  overflow-y: auto;
  background: white;
  padding: 54px 100px;
  z-index: ${zIndexTop};
  p:last-child {
    margin-bottom: 0;
  }
  @media ${devices.mobile} {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    padding: ${(p) => p.theme.spacings.huge}px ${(p) => p.theme.spacings.large}px;
  }
  ${boxShadow}
  ${roundedBorder}
`;
export const ModalWrapper = styled.div`
  ${absoluteFill}
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${zIndexTop};
`;
