import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'shared/hooks/useTranslation';
import { devices } from 'styles/devices';

const Footer: FC<{ className?: string }> = ({ className }) => {
  const translations = useTranslation();
  return (
    <footer className={className}>
      <div>{translations.footer.copyRight}</div> <div>{translations.footer.bicCode}</div>{' '}
      <div>
        <div>{translations.footer.phoneNumber}</div>
      </div>
    </footer>
  );
};
export default styled(Footer)`
  display: flex;
  flex-direction: row;
  margin-top: 60px;
  color: #818181;
  font-size: ${(p) => p.theme.fontSizeXSmall};

  & > div:first-child {
    margin-right: 100px;
  }
  div:last-child {
    margin-left: auto;
  }
  @media ${devices.mobile} {
    flex-direction: column;
    & > div {
      text-align: center;
      margin: 0 0 ${(p) => p.theme.spacings.ultraSmall}px;
      width: 100%;
    }
  }
`;
