import React, { ReactNode, Ref, ClipboardEvent } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import InputMask from 'react-input-mask';
import { InputProps } from '../InputProps';
import { smallBorderRadius } from 'styles/theme';

export interface TextInputProps extends InputProps {
  icon?: ReactNode;
  onFocus?: () => void;
  onBlur?: (e: React.ChangeEvent) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  mask?: string;
  maskPlaceholder?: string;
}

const TextInput = React.forwardRef(
  (
    {
      className,
      icon,
      inputProps,
      mask,
      maskPlaceholder,
      hasError,
      blockPaste,
      ...props
    }: TextInputProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const inputClasses = classnames({ hasError });
    return (
      <div className={className} ref={ref}>
        <StyledInput
          {...props}
          {...inputProps}
          mask={mask}
          placeholder={maskPlaceholder}
          onChange={props.onChange}
          value={props.value}
          className={inputClasses}
          onPaste={
            blockPaste
              ? (e: ClipboardEvent) => {
                  e.preventDefault();
                }
              : null
          }
        />
        {icon && icon}
      </div>
    );
  },
);

const StyledInput = styled(InputMask)<any>`
  padding: ${(p) => `${p.theme.spacings.medium}px ${p.theme.spacings.large}px`};
  border: none;
  width: 100%;
  color: ${(p) => p.theme.colors.grayDark};
  font-size: ${(p) => p.theme.fontSizeSmall};
  border: ${(p) => `1px solid ${p.theme.colors.grayLine}`};
  ${smallBorderRadius}
  transition: all 300ms ease-out;
  &::placeholder {
    color: ${(p) => p.theme.colors.grayLight};
  }
  &:focus {
    outline: none;
    border: ${(p) => `1px solid ${p.theme.colors.primary}`};
  }
  &.hasError {
    border: 1px solid red;
  }
  ${(p) =>
    p.hasError
      ? `
    border:1px solid red;
  `
      : ``}
  &:disabled {
    background-color: #eee;
  }
`;

export default styled(TextInput)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  & svg {
    position: absolute;
    transform: translate(-50%);
    margin-left: auto;
    right: 0;
  }
`;
