import * as Yup from 'yup';
import {
  isValidRegon,
  isValidPhoneNumber,
  replaceMaskChars,
  isValidName,
  isValidEmail,
} from 'shared/helpers/validation';
import { SendBody } from 'types/form';

export interface RegistrationFormValues {
  companyName: string;
  regon: string;
  activityTypeCode: string;
  productTopicCode: any;
  productId: string;
  name: string;
  email: string;
  reEmail: string;
  phoneNumber: string;
  agreementPersonalData: string;
  agreementMarketing: string;
  agreementExternalMarketing: string;
  agreementExternalTelemarketing: string;
  customer: boolean;
  isMSP: boolean;
  showExtraAgreements: boolean;
}

export const initialValues: RegistrationFormValues = {
  companyName: '',
  regon: '',
  activityTypeCode: '',
  productId: '',
  productTopicCode: undefined,
  isMSP: false,
  name: '',
  email: '',
  reEmail: '',
  phoneNumber: '',
  agreementPersonalData: '',
  agreementMarketing: '',
  agreementExternalMarketing: '',
  agreementExternalTelemarketing: '',
  customer: false,
  showExtraAgreements: false,
};

export const validationSchema = Yup.object().shape({
  companyName: Yup.string().when('isMSP', {
    is: true,
    then: Yup.string().required('required'),
  }),
  regon: Yup.string().when('isMSP', {
    is: true,
    then: Yup.string()
      .max(14, 'wrongRegonFormat')
      .test('regon', 'wrongRegonFormat', isValidRegon)
      .required('required'),
  }),
  activityTypeCode: Yup.string().when('isMSP', {
    is: true,
    then: Yup.string().required('required'),
  }),
  name: Yup.string()
    .test('name', 'noSpecialCharacters', isValidName)
    .max(500, 'fieldTooLong')
    .required('required'),
  email: Yup.string()
    .email('invalidEmailFormat')
    .max(200, 'fieldTooLong')
    .required('required')
    .test('regon', 'invalidEmailFormat', isValidEmail),
  phoneNumber: Yup.string()
    .test('phoneNumber', 'wrongPhoneFormat', isValidPhoneNumber)
    .required('required'),
  reEmail: Yup.string()
    .oneOf([Yup.ref('email'), null], 'reEmail')
    .required('required'),
  agreementPersonalData: Yup.bool().oneOf([true], 'required').required('required'),
  agreementMarketing: Yup.bool().oneOf([true], 'required').required('required'),
  agreementExternalMarketing: Yup.bool().when('showExtraAgreements', {
    is: true,
    then: Yup.bool().oneOf([true], 'required').required('required'),
  }),
  agreementExternalTelemarketing: Yup.bool().when('showExtraAgreements', {
    is: true,
    then: Yup.bool().oneOf([true], 'required').required('required'),
  }),
});

export const formValuesToApiBody = (values: RegistrationFormValues): SendBody => {
  let result: SendBody = {
    name: values.name,
    type: 'STANDARD',
    email: values.email,
    customer: values.customer,
    productId: values.productId,
    msisdn: replaceMaskChars(values.phoneNumber).replace('+48', ''),
    productTopicCode: values.productTopicCode || '',
    captcha: '',
  };
  if (values.isMSP) {
    result.companyDetails = {
      name: values.companyName,
      regon: values.regon,
      activityTypeCode: values.activityTypeCode,
    };
  }
  return result;
};
