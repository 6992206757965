export const routes = {
  default: {
    path: '/',
    title: 'Formularz',
  },
  confirm: {
    path: '/confirm/:id',
    to: (id: string) => `/confirm/${id}`,
    title: 'Potwierdź kod sms',
  },
  success: {
    path: '/success',
    title: 'Formularz wysłany',
  },
  meeting: {
    path: '/m/:id?',
    to: (id: string) => `/m/${id}`,
    title: 'Zgoda na przetwarzanie danych i nagrywanie',
  },
};
