import { captureException, setExtra } from '@sentry/browser';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';
import {
  fetchInitialSettings,
  sendFormData,
  fetchMeetingData,
  acceptMeeting,
  setRedirectUrl,
} from './actions';
import FormService from 'services/FormService';
import RecaptchaService from 'services/RecaptchaService';
import { routes } from 'constants/routes';
import { SendBody, SendApplicationResponse, InitialSettingsResponse } from 'types/form';
import { FetchMeetingSuccessResponse, AcceptMeetingSuccessResponse } from 'types/meetings';
import { matchPath } from 'react-router';

const getCategoryPath = (url: string) => url.replace(/^\/|\/$/g, '').split('/')[0];
const defaultPkoPath = 'https://www.pkobp.pl/doradcaonline';

export function* fetchTypesSaga() {
  try {
    const location: Location = yield select((state) => state.router.location);
    const { data }: { data: InitialSettingsResponse } = yield call(FormService.getTypes);

    // If can't find any location
    const isMeetingPath = matchPath(location.pathname, { path: routes.meeting.path });
    const routeByPathname = data.productList?.find((product) => product.url === location.pathname);
    let redirectUrl: string | undefined;
    if (!routeByPathname && !isMeetingPath) {
      const categoryPath = getCategoryPath(location.pathname);
      const defaultRouteByCategory = data.productList
        ?.filter((product) => getCategoryPath(product.url) === categoryPath)
        .find((route) => route.default);
      const defaultIndividualRoute = data.productList?.find(
        (product) => product.category === 'INDIVIDUAL' && product.default,
      );
      redirectUrl = defaultRouteByCategory?.redirectUrl || defaultIndividualRoute?.redirectUrl;
      yield put(
        replace(defaultRouteByCategory?.url || defaultIndividualRoute?.url || routes.default.path),
      );
    }
    yield put(setRedirectUrl(routeByPathname?.redirectUrl || redirectUrl || defaultPkoPath));
    yield put({
      type: fetchInitialSettings.done.type,
      payload: {
        result: data,
      },
    });
  } catch (error) {
    yield put({ type: fetchInitialSettings.failed.type, payload: { error } });
  }
}

export function* fetchMeetingSaga({ payload }: { payload: string }) {
  try {
    const { data }: { data: FetchMeetingSuccessResponse } = yield call(
      FormService.fetchMeeting,
      payload,
    );
    yield put({
      type: fetchMeetingData.done.type,
      payload: {
        result: data,
      },
    });
  } catch (error) {
    yield put({ type: fetchMeetingData.failed.type, payload: { error } });
  }
}

export function* acceptMeetingSaga({ payload }: { payload: string }) {
  try {
    const { data }: { data: AcceptMeetingSuccessResponse } = yield call(
      FormService.acceptMeeting,
      payload,
    );

    yield put({
      type: acceptMeeting.done.type,
      payload: {
        result: data,
      },
    });
    window.location.replace(data.url);
  } catch (error) {
    yield put({ type: acceptMeeting.failed.type, payload: { error } });
  }
}

export function* sendFormDataSaga({ payload }: { payload: SendBody }) {
  try {
    const captcha = yield RecaptchaService.execute('sendFormData');
    const { data }: { data: SendApplicationResponse } = yield call(FormService.sendFormData, {
      ...payload,
      captcha,
    });
    yield put(push(routes.confirm.to(data.applicationId)));

    yield put({ type: sendFormData.done.type, payload: { result: data } });
  } catch (error) {
    if (error?.response) {
      setExtra('axios-response', error.response?.data);
    }
    captureException(error);
    yield put({ type: sendFormData.failed.type, payload: { error } });
  }
}

export default [
  function* () {
    yield takeLatest(fetchInitialSettings.started, fetchTypesSaga);
  },
  function* () {
    yield takeLatest(sendFormData.started, sendFormDataSaga);
  },
  function* () {
    yield takeLatest(fetchMeetingData.started, fetchMeetingSaga);
  },
  function* () {
    yield takeLatest(acceptMeeting.started, acceptMeetingSaga);
  },
];
