import React, { FC } from 'react';
import styled from 'styled-components';
import { useField } from 'formik';
import { Option, GroupOption } from 'types/option';
import Select from '../Select';
import { ArrowDownIcon } from '../Icons';
import { InputProps } from '../InputProps';
import FieldWrapper from '../FieldWrapper';

interface SelectFieldProps extends InputProps {
  options: (Option | GroupOption)[];
  isClearable?: boolean;
  isMulti?: boolean;
  handleChange?: (option: Option) => void;
  defaultValue?: Option;
  isSearchable?: boolean;
  noOptionsMessage?: () => any;
}

const StyledDropdownIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  margin-top: -5px;
  svg {
    height: 25px;
    fill: ${(p) => p.theme.colors.primary};
  }
`;

const SelectField: FC<SelectFieldProps> = ({
  className,
  name,
  label,
  labelVariant,
  options,
  placeholder = '',
  isClearable,
  isMulti,
  handleChange,
  defaultValue,
  required,
  isSearchable = true,
  noOptionsMessage,
  disabled,
}) => {
  const [field, meta] = useField<Option | number[]>(name);

  const isGroupOption = (option: Option | GroupOption): option is GroupOption =>
    (option as GroupOption).options !== undefined;
  const parsedOptions = options.flatMap((option) =>
    isGroupOption(option) ? option.options : option,
  );
  const notSelectedValue = isMulti ? [] : null;
  const singleSelectedValue = parsedOptions.find(
    (option) => String(option.value) === String(field.value),
  );
  const { value } = field;
  const multiSelectedValues = Array.isArray(value)
    ? parsedOptions.filter((option) => value.includes(Number(option.value)))
    : false;

  const currentValue = singleSelectedValue || multiSelectedValues;

  return (
    <FieldWrapper
      name={name}
      label={label}
      labelVariant={labelVariant}
      className={className}
      required={required}
      hasError={meta.touched && !!meta.error}
    >
      <Select
        classNamePrefix="react-select"
        isDisabled={disabled}
        onChange={(option: Option | Option[]) => {
          if (Array.isArray(option)) {
            field.onChange({
              target: { value: option.map((o) => o.value), name: field.name },
            });
            return;
          }
          if (handleChange) {
            handleChange(option);
          }
          field.onChange({
            target: { value: option ? option.value : notSelectedValue, name: field.name },
          });
        }}
        value={currentValue}
        options={options}
        placeholder={placeholder}
        isClearable={isClearable}
        isMulti={isMulti}
        defaultValue={defaultValue}
        isSearchable={isSearchable}
        noOptionsMessage={noOptionsMessage}
        components={{
          DropdownIndicator: () => (
            <StyledDropdownIndicator>
              <ArrowDownIcon />
            </StyledDropdownIndicator>
          ),
        }}
      />
    </FieldWrapper>
  );
};

export default styled(SelectField)``;
