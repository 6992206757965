import React, { ReactNode, Ref } from 'react';
import styled, { css, DefaultTheme, ThemeProps, StyledComponent } from 'styled-components';
import { BorderStyle, ButtonType, Color, Size } from './types';
import { buttonColorStyle, buttonSizeStyle } from './styles';
import { LeftIcon, RightIcon } from './ButtonIcon';
import { NumberValues, fontRegular } from 'styles/theme';
import CircleLoader from '../CircleLoader';

export interface ButtonProps {
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  color?: Color;
  borderStyle?: BorderStyle;
  disabled?: boolean;
  size?: Size;
  type?: ButtonType;
  className?: string;
  isLoading?: boolean;
  element?: 'button' | 'a';
  children?: string | ReactNode;
  href?: string;
}

interface StyledProps extends ThemeProps<DefaultTheme> {
  color: Color;
  size: Size;
  borderStyle: BorderStyle;
  disabled?: boolean;
  className?: string;
}

interface LoaderWrapperProps {
  isLoading: boolean;
}

const loaderWrapperWidth: NumberValues = 16;
const activeLoaderStyles = css`
  width: ${loaderWrapperWidth}px;
  margin-left: ${(p) => p.theme.spacings.small}px;
  opacity: 1;
`;
const loaderStyles = css`
  width: 0;
  margin-left: 0;
  opacity: 0;
`;

const LoaderWrapper = styled.div<LoaderWrapperProps>`
  overflow: hidden;
  transition: all 0.3s;
  ${(p) => (p.isLoading ? activeLoaderStyles : loaderStyles)}
`;

const Button = React.forwardRef((props: ButtonProps, ref: Ref<any>) => {
  const {
    children,
    disabled,
    color = 'primary',
    leftIcon,
    rightIcon,
    size = 'medium',
    borderStyle = 'solid',
    className,
    onClick,
    type = 'button',
    isLoading,
    element = 'button',
    href,
  } = props;

  const ButtonComponent: StyledComponent<any, DefaultTheme, StyledProps, never> =
    element === 'button' ? StyledButton : StyledHyperlink;

  return (
    <ButtonComponent
      color={color}
      size={size}
      borderStyle={borderStyle}
      className={className}
      onClick={isLoading || disabled ? null : onClick}
      type={type}
      disabled={disabled}
      href={element === 'a' ? href : null}
    >
      {leftIcon && (
        <LeftIcon icon={leftIcon} size={size} color={color} noMargin={children === undefined} />
      )}
      {children}
      {rightIcon && <RightIcon icon={rightIcon} size={size} color={color} />}
      <LoaderWrapper isLoading={!!isLoading}>
        <CircleLoader white small />
      </LoaderWrapper>
    </ButtonComponent>
  );
});

const buttonStyles = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: ${fontRegular};
  font-style: normal;
  font-weight: normal;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  @media (hover) {
    &:hover {
      background-color: ${(p) => p.theme.colors.primaryDark};
      opacity: 0.7;
      color: #ffffff;
      & svg * {
        fill: #ffffff;
      }
    }
  }
  border-radius: 5px 0px 5px 0px;
  ${(p: StyledProps) => buttonSizeStyle(p.size, p)};
  ${(p: StyledProps) => buttonColorStyle(p.color, p)};
  &:disabled {
    background: grey;
    border: none;
  }
  border-style: ${(p: StyledProps) => p.borderStyle};
`;

const StyledButton = styled.button<StyledProps>`
  ${buttonStyles}
`;

const StyledHyperlink = styled.a<StyledProps>`
  ${buttonStyles}
  text-decoration: none;
  ${(p) =>
    p.disabled &&
    `
    opacity: 0.5;
    color: ${p.theme.colors.disabled};
    border: 1px solid ${p.theme.colors.disabled};

    & svg * {
      fill: ${p.theme.colors.disabled};
    }
  `}
`;

export default Button;
