import { AxiosResponse } from 'axios';
import api from 'shared/services/api';
import { SendBody, InitialSettingsResponse, SendApplicationResponse, VerifyRequest } from 'types/form';
import { FetchMeetingResponse, AcceptMeetingResponse } from 'types/meetings';

class FormService {
  public getTypes: () => Promise<AxiosResponse<InitialSettingsResponse>> = () => api().get(`/initialSettings`);

  public sendFormData: (body: SendBody) => Promise<AxiosResponse<SendApplicationResponse>> = (
    body,
  ) => api().post(`/application/register`, body);

  public verify: (body: VerifyRequest) => Promise<AxiosResponse<any>> = (body) =>
    api().post(`/application/verify`, body);

  public fetchMeeting: (id: string) => Promise<AxiosResponse<FetchMeetingResponse>> = (id) =>
    api().get(`/m/${id}`);

  public acceptMeeting: (id: string) => Promise<AxiosResponse<AcceptMeetingResponse>> = (id) =>
    api().post(`/m/${id}`);
}
export default new FormService();
