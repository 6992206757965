import { combineReducers } from 'redux';
import { History } from 'history';
import { connectRouter } from 'connected-react-router';
import { RootState } from './rootState';
import { translationReducer } from './translation/reducer';
import { formReducer } from './form/reducer';

export const rootReducer = (history: History) =>
  combineReducers<RootState>({
    router: connectRouter(history),
    translations: translationReducer,
    form: formReducer,
  });
