import styled from 'styled-components';
import { devices } from 'styles/devices';

export const H1 = styled.h1<{ noMargin?: boolean }>`
  color: black;
  font-size: ${(p) => p.theme.fontSizes.H1};
  @media ${devices.mobile} {
    font-size: ${(p) => p.theme.fontSizes.H2};
    line-height: 40px;
  }
  font-weight: ${(p) => p.theme.fontWeights.bold};
  line-height: 56px;
  margin-bottom: ${(p) => (p.noMargin ? 0 : `52`)}px;
  margin-top: 0;
`;
export const H2 = styled.h2<{ noMargin?: boolean }>`
  color: black;
  font-size: ${(p) => p.theme.fontSizes.H2};
  @media ${devices.mobile} {
    font-size: ${(p) => p.theme.fontSizes.H3};
  }
  font-weight: ${(p) => p.theme.fontWeights.regular};
  margin-bottom: ${(p) => (p.noMargin ? 0 : p.theme.spacings.extraLarge)}px;
  margin-top: 0;
`;
export const H3 = styled.h3<{ noMargin?: boolean }>`
  color: black;
  font-size: ${(p) => p.theme.fontSizes.H3};
  @media ${devices.mobile} {
    font-size: ${(p) => p.theme.fontSizes.H4};
  }
  font-weight: ${(p) => p.theme.fontWeights.regular};
  margin-bottom: ${(p) => (p.noMargin ? 0 : p.theme.spacings.extraLarge)}px;
  margin-top: 0;
`;
export const H4 = styled.h4<{ noMargin?: boolean }>`
  color: black;
  font-size: ${(p) => p.theme.fontSizes.H4};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  margin-bottom: ${(p) => (p.noMargin ? 0 : p.theme.spacings.medium)}px;
  margin-top: 0;
`;
export const H5 = styled.h5<{ noMargin?: boolean }>`
  color: black;
  font-size: ${(p) => p.theme.fontSizes.H5};
  font-weight: ${(p) => p.theme.fontWeights.regular};
  margin-bottom: ${(p) => (p.noMargin ? 0 : p.theme.spacings.medium)}px;
  margin-top: 0;
`;
export const Paragraph = styled.p<{ noMargin?: boolean }>`
  color: ${(p) => p.theme.colors.black};
  font-size: ${(p) => p.theme.fontSizeSmall};
  line-height: 20px;
  margin-bottom: ${(p) => (p.noMargin ? 0 : p.theme.spacings.huge)}px;
  margin-top: 0;
  a {
    color: ${(p) => p.theme.colors.primary};
  }
`;
