import Select from 'react-select';
import styled from 'styled-components';

export default styled(Select)`
  // Waiting for https://github.com/JedWatson/react-select/issues/3754
  [class^='css-'] {
    padding: 1px 0 0;
    margin: 0;
  }
  .react-select__value-container {
    padding: ${(p) => `${p.theme.spacings.medium}px ${p.theme.spacings.large}px`};
  }
  .react-select__placeholder {
    color: ${(p) => p.theme.colors.grayLight};
  }
  .react-select__single-value {
    color: ${(p) => p.theme.colors.grayMedium};
  }

  .react-select__option--is-selected {
    background: ${(p) => p.theme.colors.primary};
  }
  .react-select__control--is-focused,
  .react-select__control{
    max-height: 43px;
    border: ${(p) => `1px solid ${p.theme.colors.grayLine}`};
  }

  .react-select__control--is-focused,
  .react-select__control:hover {
    border: ${(p) => `1px solid ${p.theme.colors.primary}`};
    box-shadow: none;
  }

  .react-select__multi-value {
    margin: 0 2px;
    padding: 2px;
  }
  .react-select__indicator-separator {
    opacity: 0;
  }
  .react-select__indicator {
    *  {
      fill: ${(p) => p.theme.colors.primary}; 
    }
  }
  
`;
