import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as actions from './actions';
import { initialTranslationState, TranslationState } from './state';
import translations from 'constants/translations';

export const translationReducer = reducerWithInitialState(initialTranslationState).case(
  actions.setTranslates,
  (state, languageKey): TranslationState => ({
    ...state,
    data: translations[languageKey],
  }),
);
