import React from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';
import RegistrationForm from './components/RegistrationForm';
import {
  initialValues,
  RegistrationFormValues,
  formValuesToApiBody,
  validationSchema,
} from './validate';
import {
  useFormActions,
  useFormInitialSettingsState,
  useFormInitialSettingsDataState,
} from 'store/form/hooks';
import CircleLoader from 'shared/components/CircleLoader';
import { useTranslation } from 'shared/hooks/useTranslation';
import Error from 'shared/components/Error';
import Header from './components/Header';
import { useLocation } from 'react-router';
import { Option } from 'types/option';
import { topicCodes } from 'constants/types';
import { Product } from '../../types/form';
import { useExtraAgreements } from '../../shared/hooks/useExtraAgreements';

const StyledLoader = styled.div`
  width: 100%;
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Params {
  0?: 'indywidualny' | 'firmy';
  productId?: string;
}

export default () => {
  const translations = useTranslation();
  const { sendFormData } = useFormActions();
  const { type } = useFormInitialSettingsState();
  const initialSettings = useFormInitialSettingsDataState();
  const { shouldShowExtra } = useExtraAgreements();

  const onSubmit = (values: RegistrationFormValues) => {
    sendFormData(formValuesToApiBody(values));
  };
  const location = useLocation();
  const currentRoute: Product | undefined = initialSettings?.productList?.find(
    (product) => product.url === location.pathname,
  );

  const showExtraAgreements = shouldShowExtra(location.pathname);
  const isMSP = currentRoute?.category === 'MSP';
  const topicCodesOptions: Option[] = currentRoute
    ? currentRoute.topicList.map((topic) => ({ value: topic.code, label: topic.description }))
    : topicCodes;
  return (
    <>
      <Header />
      {type === 'loading' && (
        <StyledLoader>
          <CircleLoader />
        </StyledLoader>
      )}
      {type === 'error' && <Error>{translations.form.apiErrors.fetchTypesError}</Error>}
      {type === 'loaded' && (
        <Formik
          initialValues={{
            ...initialValues,
            isMSP,
            showExtraAgreements,
            productId: currentRoute?.id || '',
            productTopicCode: topicCodesOptions.length === 1 ? topicCodesOptions[0].value : '',
          }}
          validationSchema={validationSchema}
          validateOnChange
          validateOnBlur
          onSubmit={onSubmit}
        >
          {(props) => (
            <RegistrationForm
              {...props}
              isMSP={isMSP}
              showExtraAgreements={showExtraAgreements}
              topicCodesOptions={topicCodesOptions}
              currentRoute={currentRoute}
            />
          )}
        </Formik>
      )}
    </>
  );
};
