import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { init } from '@sentry/browser';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { theme } from 'styles/theme';
import { fontRegular } from 'styles/theme';
import { store, history } from './store';
import App from './App';
import 'index.css';
import { isDev } from 'shared/helpers/config';
import { formatDevVersion } from 'shared/helpers/format-version';
import { version } from './version.json';

const formattedVersion = isDev() ? formatDevVersion(version) : version;
init({
  dsn: 'https://a90967f6a8ff43ef8d82d35543262c29@o390910.ingest.sentry.io/5242042',
  environment: process.env.REACT_APP_TYPE,
  release: formattedVersion,
});

const GlobalStyle = createGlobalStyle`
  html,body {
    height: 100%;
    min-height: 100%;
    font-family: ${fontRegular}, sans-serif;
    padding: 0;
    margin: 0;
  }
  * { 
    box-sizing: border-box;
  }
`;

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
