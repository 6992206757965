import React from 'react';
import { FormikProps } from 'formik';
import { useTranslation } from 'shared/hooks/useTranslation';
import { Col, Row } from 'shared/components/Grid';
import { RegistrationFormValues } from '../../validate';
import { Paragraph } from 'shared/components/Typography';
import ControlField from 'shared/components/ControlField';
import ControlGroup from 'shared/components/ControlGroup';
import ModalHyperlink from 'shared/components/ModalHyperlink';
import Hyperlink from 'shared/components/Hyperlink';
import styled from 'styled-components';
import ImportantInformations from '../ImportantInformations';
import PersonalInformations from '../PersonalInformations';
import MarketingInformations from '../MarketingInformations';
import ExternalMarketingInformations from '../ExternalMarketingInformations';
import ExternalTelemarketingInformations from '../ExternalTelemarketingInformations';
import Checkbox from 'shared/components/Checkbox';
import { Option } from '../../../../types/option';

const ChildAgreements = styled.div`
  padding-left: 35px;
  padding-top: 14px;
`;

interface RegistrationFormProps extends FormikProps<RegistrationFormValues> {
  isMSP: boolean;
  showExtraAgreements: boolean;
  topicCodesOptions: Option[];
}

export default ({ setFieldValue, values, validateForm, showExtraAgreements }: RegistrationFormProps) => {
  const translations = useTranslation();

  const onSelectAll = () => {
    const selected = isAllSelected();

    ['agreementMarketing', 'agreementPersonalData', 'agreementExternalMarketing', 'agreementExternalTelemarketing']
      .forEach((field) => setFieldValue(field, selected));
    setTimeout(() => validateForm(), 100);
  };

  const isAllSelected = () => {
    const { agreementMarketing, agreementPersonalData, agreementExternalMarketing, agreementExternalTelemarketing } = values;

    const fieldValues = [agreementMarketing, agreementPersonalData]
      .concat(showExtraAgreements ? [agreementExternalMarketing, agreementExternalTelemarketing] : []);
    return fieldValues.some((selected) => !selected);
  }

  const allSelected = isAllSelected()

  return (
    <Row>
      <Col type="medium">
        <ControlGroup orientation="vertical" name="agreements" required>
          <Checkbox
            label={<strong>{translations.form.labels.acceptAll}</strong>}
            name="selectAll"
            handleChange={onSelectAll}
            selected={!allSelected}
          />
          <ChildAgreements>
            <ControlField
              type="checkbox"
              label={
                <span>
                  {translations.form.labels.acceptPersonalData}
                  <br />
                  <ModalHyperlink label={translations.form.labels.seeAll}>
                    <PersonalInformations />
                  </ModalHyperlink>
                </span>
              }
              name="agreementPersonalData"
              required
            />
            <ControlField
              type="checkbox"
              label={
                <span>
                  {translations.form.labels.acceptMarketing}
                  <br />
                  <ModalHyperlink label={translations.form.labels.seeAll}>
                    <MarketingInformations />
                  </ModalHyperlink>
                </span>
              }
              name="agreementMarketing"
              required
            />
            {showExtraAgreements && (
              <>
                <ControlField
                  type="checkbox"
                  label={
                    <span>
                  {translations.form.labels.acceptExternalMarketing}
                      <br />
                  <ModalHyperlink label={translations.form.labels.seeAll}>
                    <ExternalMarketingInformations />
                  </ModalHyperlink>
                </span>
                  }
                  name="agreementExternalMarketing"
                  required
                />
                <ControlField
                  type="checkbox"
                  label={
                    <span>
                  {translations.form.labels.acceptExternalTelemarketing}
                      <br />
                  <ModalHyperlink label={translations.form.labels.seeAll}>
                    <ExternalTelemarketingInformations />
                  </ModalHyperlink>
                </span>
                  }
                  name="agreementExternalTelemarketing"
                  required
                />
              </>
            )}
          </ChildAgreements>
        </ControlGroup>
        <Paragraph>
          {translations.form.labels.objections}
          <br />
          <ModalHyperlink label={translations.form.labels.getKnownWithAllInfo}>
            <ImportantInformations />
          </ModalHyperlink>
        </Paragraph>
        <Paragraph>
          {translations.form.labels.adminData}
          <br />
          <Hyperlink href="https://www.pkobp.pl/rodo/" target="_blank">
            {translations.form.labels.adminDataLink}
          </Hyperlink>
        </Paragraph>
      </Col>
    </Row>
  );
};
