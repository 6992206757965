import React, { FC, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { ModalWrapper, ModalBackground, ModalContent, ModalCloseButton } from './styled-components';
import FadeInTransition from '../FadeInTransition';
import { CloseIcon } from '../Icons';

export interface ModalProps {
  onClose: () => void;
  children?: ReactNode;
  isOpen: boolean;
  className?: string;
}

const Modal: FC<ModalProps> = ({ onClose, children, isOpen, className }) => {
  const rootModalContainer = document.getElementById('root-modal') as HTMLElement;
  const modalMarkup = (
    <FadeInTransition isOpen={isOpen}>
      <ModalWrapper>
        <ModalBackground onClick={onClose} />
        <ModalContent className={className}>
          <ModalCloseButton onClick={onClose}>
            <CloseIcon />
          </ModalCloseButton>
          {children}
        </ModalContent>
      </ModalWrapper>
    </FadeInTransition>
  );
  return ReactDOM.createPortal(modalMarkup, rootModalContainer);
};

export default Modal;
