import React from 'react';
import { Paragraph } from 'shared/components/Typography';

export default () => (
  <Paragraph>
    Wyrażam zgodę na otrzymywanie od PKO Banku Polskiego S.A. informacji o charakterze marketingowym
    (w tym informacji handlowych) za pomocą środków komunikacji elektronicznej, telekomunikacyjnych
    urządzeń końcowych i automatycznych systemów wywołujących.
  </Paragraph>
);
