import React, { FC } from 'react';
import styled, { css } from 'styled-components';
import classnames from 'classnames';
import { useField, FieldConfig } from 'formik';
import { CheckIcon } from '../Icons';
import { InputProps } from '../InputProps';

export interface ControlFieldProps extends InputProps {
  type: 'checkbox' | 'radio';
  small?: boolean;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  fullWidth?: boolean;
  noMargin?: boolean;
}

const ControlField: FC<ControlFieldProps> = ({
  className,
  disabled = false,
  handleChange,
  label,
  name,
  type,
  value,
  required,
}) => {
  const id = `${type}-${name}`;
  const config: FieldConfig = { name, type, value };
  const [field, meta] = useField(config);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (handleChange) {
      handleChange(e);
      return;
    }
    field.onChange(e);
  };
  const hasError = meta.touched && !!meta.error;
  const controlClasses = classnames('control', { 'control--error': hasError });
  return (
    <label className={className} htmlFor={id}>
      <input id={id} {...field} disabled={disabled} type={type} onChange={onChange} />
      <div className={controlClasses}>{type === 'checkbox' && <CheckIcon />}</div>
      <StyledLabel>
        {label}
      </StyledLabel>
    </label>
  );
};
const StyledLabel = styled.span`
  margin-top: ${(p) => `${p.theme.spacings.ultraSmall}px`};
`;
export default styled(ControlField)`
  display: inline-flex;
  width: ${(p) => (p.fullWidth ? '100%' : 'auto')};
  page-break-inside: avoid;
  break-inside: avoid-column;
  vertical-align: middle;
  align-items: flex-start;
  cursor: pointer;
  font-size: ${(p) => (p.labelVariant === 'medium' ? p.theme.fontSize : p.theme.fontSizeSmall)};
  margin-bottom: ${(p) => (p.noMargin ? 0 : `${p.theme.spacings.medium}px`)};
  margin-right: ${(p) => (p.noMargin ? 0 : `${p.theme.spacings.medium}px`)};
  & .control {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${(p) => `1px solid ${p.theme.colors.grayLight}`};
    background-color: white;
    border-radius: ${(p) => (p.type === 'checkbox' ? '2px' : '50%')};
    margin-right: ${(p) => (p.noMargin ? 0 : `${p.theme.spacings.large}px`)};
    position: relative;
    transition: 0.12s;
    &--error {
      border-color: ${(p) => p.theme.colors.error};
    }
    & svg {
      width: inherit;
      height: inherit;
      opacity: 0;
      transform: scale(1.4);
      transition: 0.12s;
      & * {
        fill: white;
      }
    }
  }
  & input {
    position: absolute;
    padding: 0;
    margin: 0;
    width: 0;
    height: 0;
    visibility: hidden;
    &:checked {
      ~ .control {
        background-color: ${(p) => p.theme.colors.primaryDark};
        border: ${(p) => `1px solid ${p.theme.colors.primaryDark}`};
      }
    }
    ${(p) =>
      p.type === 'checkbox'
        ? css`
            &:checked ~ .control svg {
              opacity: 1;
            }
          `
        : css`
            &:checked ~ .control:after {
              content: '';
              position: absolute;
              display: block;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              width: 12px;
              height: 12px;
              border-radius: 50%;
              background-color: white;
            }
          `}
  }
`;
