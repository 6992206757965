import React, { useState } from 'react';
import { captureException, setExtra } from '@sentry/browser';
import { Formik } from 'formik';
import { useParams, useHistory } from 'react-router';
import Button from 'shared/components/Button';
import styled from 'styled-components';
import { Row, Col } from 'shared/components/Grid';
import { H3, Paragraph } from 'shared/components/Typography';
import CodeField from 'shared/components/CodeField';
import FormService from 'services/FormService';
import { useTranslation } from 'shared/hooks/useTranslation';
import validate, { initialValues, ConfirmValues, valuesToToken } from './validate';
import { routes } from 'constants/routes';
import Error from 'shared/components/Error';
import Header from '../Header';

const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin-top: ${(p) => p.theme.spacings.extraLarge}px;
`;

const StyledH3 = styled(H3)`
  margin-bottom: ${(p) => p.theme.spacings.medium}px;
`;

const StyledParagraph = styled(Paragraph)`
  color: #6c757d;
  font-size: 16px;
  font-weight: bold;
`;

const DescriptionParagraph = styled(Paragraph)`
  font-size: 16px;
  &::after {
    content: '*';
    color: ${(p) => p.theme.colors.red};
  }
`;

export const Confirm = () => {
  const translations = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setError] = useState(false);
  const { id } = useParams();
  const history = useHistory();
  const onSubmit = (values: ConfirmValues) => {
    if (!id) return;
    setIsLoading(true);
    const token = valuesToToken(values);
    FormService.verify({ applicationId: id, token })
      .then(() => {
        history.replace(routes.success.path);
        setIsLoading(false);
      })
      .catch((e) => {
        if (e?.response) {
          setExtra('axios-response', e.response?.data);
        }
        captureException(e);
        setError(true);
        setIsLoading(false);
      });
  };
  return (
    <>
      <Header />
      <Col align="center">
        <StyledParagraph>{translations.common.confirmation}</StyledParagraph>
        <StyledH3>{translations.confirm.title}</StyledH3>
        <DescriptionParagraph>{translations.confirm.description}</DescriptionParagraph>
        <Formik
          initialValues={initialValues}
          validate={validate}
          validateOnBlur={false}
          onSubmit={onSubmit}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <Row justify="center" align="center">
                <CodeField name="0" autoFocus />
                <CodeField name="1" />
                <CodeField name="2" />
                <CodeField name="3" />
                <CodeField name="4" />
                <CodeField name="5" />
              </Row>
              <StyledButton
                type="submit"
                disabled={!props.dirty || !props.isValid}
                isLoading={isLoading}
              >
                {translations.common.confirm}
              </StyledButton>
              {hasError && <Error>{translations.form.apiErrors.wrongCodeError}</Error>}
            </Form>
          )}
        </Formik>
      </Col>
    </>
  );
};

export default Confirm;
